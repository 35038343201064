import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './main.css';
import Destiny from './pages/destiny';
import LostPage from './pages/lost-page';
import Molu from './pages/molu';
import Phillip from './pages/phillip';
import Root from './pages/root';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Root />}/>
        <Route path="/molu" element={<Molu />}/>
        <Route path="/destiny" element={<Destiny />} />
        <Route path="/phillip" element={<Phillip />} />
        <Route path="*" element={<LostPage />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
