import React from 'react';
import PhillipImg from '../../assets/phillip.png';
import PhillipSong from '../../assets/phillipsong.mp3';
import './styles.scss';


function Phillip() {
  enum Party {
    None = "",
    Time = "party",
  }

  let [party, setParty] = React.useState(Party.Time);

  const onPhillipClicked = () => {
    if (party === Party.Time) {
      setParty(Party.None);
    } else {
      setParty(Party.Time);
    }
  }

  return (
    <main className='phillip'>
      <h2>Phill ip</h2>
      <div className="phillip__controls">
        <div className="phillip__audio">
          <span className={`${party} poop`}></span>
          <img className={`${party}`} src={PhillipImg} alt="phillip" onClick={onPhillipClicked} />
          <audio  id="partyAudio" autoPlay controls src={PhillipSong}></audio>
        </div>
      </div>
    </main>
  );
}

export default Phillip;
