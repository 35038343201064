import React from 'react';

function LostPage() {
  return (
    <main>
      <div className="">
          <h2>Well Foot</h2>
          <p>You are in the wrong spot now.</p>
      </div>
    </main>
  );
}

export default LostPage;
