import React from 'react';
import './styles.scss';

function Destiny() {
  enum Guardian {
    None = "",
    Titan = "titan",
    Hunter = "hunter",
    Warlock = "warlock",
  }

  let [guardian, setGuardian] = React.useState(Guardian.None);

  return (
    <main className="destiny">
      <div className={`${guardian} the-cade-6-unit-dank-af-anmation`}>
        <span className="circle circle--1"></span>
        <span className="circle circle--2"></span>
        <span className="circle circle--3"></span>
        <span className="circle circle--4"></span>
        <div className="line-group line-group--1">
          <span className="line line--1"></span>
          <span className="line line--2"></span>
          <span className="line line--3"></span>
          <span className="line line--4"></span>
        </div>
        <div className="line-group line-group--2">
          <span className="line line--1"></span>
          <span className="line line--2"></span>
          <span className="line line--3"></span>
          <span className="line line--4"></span>
        </div>
        <div className="line-group line-group--3">
          <span className="line line--1"></span>
          <span className="line line--2"></span>
          <span className="line line--3"></span>
          <span className="line line--4"></span>
        </div>
        <div className="shape-group">
          <span className="shape shape--1"></span>
          <span className="shape shape--2"></span>
          <span className="shape shape--3"></span>
          <span className="shape shape--4"></span>
        </div>
      </div>
      <div className="button-container">
        <div>
          <button className="button button--warlock" onClick={() => setGuardian(Guardian.Warlock)}>Warlock</button>
          <button className="button button--titan" onClick={() => setGuardian(Guardian.Titan)}>Titan</button>
          <button className="button button--hunter" onClick={() => setGuardian(Guardian.Hunter)}>Hunter</button>
        </div>
        <div className="button-container__clear">
          <button className="button"  onClick={() => setGuardian(Guardian.None)}>Clear</button>
        </div>
      </div>
    </main>
  );
}

export default Destiny;
